import React, { Component, Fragment } from "react";
import PropTypes from "react-proptypes";
import cx from "classnames";
import "./index.scss";
import IconPlus from "../../../img/icon-plus.svg"
import IconMinus from "../../../img/icon-minus.svg"

export default class FaqItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    }
  }

  toggleExpand = () => this.setState(prevState => ({ expanded: !prevState.expanded }));

  componentDidUpdate() {
    this.props.onUpdate();
  }

  render() {
    const { node } = this.props;
    const { expanded } = this.state;

    return (
      <div className="faq__item">
        <div
          className={cx("faq__item_title", { "faq__item_title--highlighted": expanded })}
          onClick={this.toggleExpand}
        >
          <h2 className="faq__item_title_text">{node.frontmatter ? node.frontmatter.title : null}</h2>
          <img className="faq__item_title_icon" src={expanded ? IconMinus : IconPlus} alt="" />
        </div>

        <div className={cx("faq__item_content", { "faq__item_content--visible": expanded })}
             dangerouslySetInnerHTML={{ __html: node.html }}>
        </div>
      </div>
    );
  }
}

FaqItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  onUpdate: PropTypes.func,
};
