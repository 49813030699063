import React, { Component, Fragment } from "react";
import PropTypes from "react-proptypes";
import ContextConsumer from "../../Context";
import "./index.scss";
import FaqItem from "./FaqItem";

export default class Faq extends Component {
  render() {
    const { onUpdate, items, post } = this.props;

    // TODO: FIX {marginBottom: '30px'}

    return (
      <ContextConsumer>
        {() => {

          return (
            <div className="faq text-content">

              <div style={{marginBottom: '30px'}} dangerouslySetInnerHTML={{ __html: post.html }}></div>

              {items.map((node, index) => (
                <FaqItem node={node} key={index} onUpdate={onUpdate} />
              ))}
            </div>
          )
        }}
      </ContextConsumer>
    );
  }
}

Faq.propTypes = {
  onUpdate: PropTypes.func,
};

Faq.defaultProps = {
  onUpdate: null,
};
