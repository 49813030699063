import React, { Component, Fragment } from "react";
import ContextConsumer from "../../Context";
import "./index.scss";
import Button from "../Button";

export default class BoxOrder extends Component {
  render() {

    return (
      <ContextConsumer>
        {({ toggleModal }) => (
          <div className="boxorder">
            <p className="boxorder__title">Bli svensk medborgare</p>
            <p className="boxorder__text">
              Ansök genom att klicka på knappen, svara på frågorna och fyll i dina uppgifter.
            </p>

            <div className="boxorder__button_wrapper">
              <Button to="/order" shape="flatBorderedTransparent" content="ANSÖK NU" onClick={() => toggleModal()} />
            </div>
          </div>
        )}
      </ContextConsumer>
    );
  }
}
