import React, {Component, Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import SEO from '../components/SEO/SEO';
import UnorderedListSection from '../components/UnorderedListSection';
import BlockWithFixedAside from '../components/BlockWithFixedAside';
import Faq from '../components/Faq';
import Config from '../../data/SiteConfig';

export default class FaqPage extends Component {
    render() {
        let edges = this.props.data.allMarkdownRemark.edges;

        let content = [];
        let post = null;

        edges.forEach(edge => {
            let {node} = edge;

            if ( node.fields && node.fields.slug ) {
                post = node;
            } else {
                content.push(node);
            }
        });

        return (
            <Fragment>
                <Helmet title={[post.frontmatter.title, Config.siteTitle].join(' | ')} />

                <BlockWithFixedAside title={post.frontmatter.title}
                                     content={<Faq post={post} items={content}/>}/>

                <UnorderedListSection/>
            </Fragment>
        );
    }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PageByPost($post: String!) {
    allMarkdownRemark(filter: {fields: {post: {eq: $post}}}, sort:{fields: [fileAbsolutePath]}) {
      edges {
        node {
          html
          
          frontmatter {
            title
          }
          
          fields {
            slug
            post
          }                    
        }
      }
    }
  }
`;
