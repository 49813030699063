import React, { Component, Fragment } from "react";
import PropTypes from "react-proptypes";
import cx from "classnames";
import "./index.scss";
import BoxOrder from "../BoxOrder";

export default class BoxWithFixedAside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asideStickToBottom: false,
    }
  }

  controlAsideSticky = () => {
    const aside = Array.from(document.getElementsByClassName("bwfa__aside"))[0];

    // If aside is not rendered (in mobile mode): do nothing
    if (!aside) {
      return;
    }

    const asideTop = parseInt(window.getComputedStyle(aside).getPropertyValue("top"), 10);
    const asideHeight = aside.getBoundingClientRect().height;

    const faqContent = Array.from(document.getElementsByClassName("bwfa__content"))[0];
    const faqContentTopInViewport = faqContent.getBoundingClientRect().top;
    const faqContentBottomInViewport = faqContent.getBoundingClientRect().bottom;
    const faqContentPaddingTop = parseInt(window.getComputedStyle(faqContent).getPropertyValue("padding-top"), 10);
    const faqContentPaddingBottom = parseInt(window.getComputedStyle(faqContent).getPropertyValue("padding-bottom"), 10);

    const initialAsideTopInViewport = faqContentTopInViewport + faqContentPaddingTop;

    // Normal or sticky header (when sticky header is shown it's placed before the normal one in DOM)
    const header = Array.from(document.getElementsByClassName("header"))[0];
    const headerBottomInViewport = header.getBoundingClientRect().bottom;

    if (initialAsideTopInViewport < headerBottomInViewport) {
      const nextAsideTop = headerBottomInViewport - initialAsideTopInViewport;

      const maxAsideBottomInViewport = faqContentBottomInViewport - faqContentPaddingBottom;
      const nextAsideBottomInViewport = initialAsideTopInViewport + nextAsideTop + asideHeight;

      if (nextAsideBottomInViewport < maxAsideBottomInViewport) {
        aside.style.top = `${nextAsideTop}px`;

        if (this.state.asideStickToBottom) {
          this.setState({ asideStickToBottom: false });
        }
      } else {
        if (asideTop !== 0) {
          aside.style.top = 0;
        }

        if (!this.state.asideStickToBottom) {
          this.setState({ asideStickToBottom: true });
        }
      }
    } else {
      if (asideTop !== 0) {
        aside.style.top = 0;
      }

      if (this.state.asideStickToBottom) {
        this.setState({ asideStickToBottom: false });
      }
    }
  };

  componentDidMount() {
    this.controlAsideSticky();
    window.addEventListener("scroll", this.controlAsideSticky)
  }

  render() {
    const { asideStickToBottom } = this.state;
    const { title, content } = this.props;

    return (
      <div className="bwfa">
        <h2 className="bwfa__title">{title}</h2>
        <div className="bwfa__content_bg">
          <div className={cx("bwfa__content")}>
            {React.cloneElement(content, { onUpdate: this.controlAsideSticky })}

            <div className={cx("bwfa__aside", { "bwfa__aside--stick_to_bottom": asideStickToBottom })}>
              <BoxOrder />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BoxWithFixedAside.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};
